
   
   .card{
        width: 20%;
        display: inline-block;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px; 
        margin: 2%;
       }
   
   .image img{
     width: 100%;
     border-top-right-radius: 5px;
     border-top-left-radius: 5px;
     
   
    
    }
   
   .title{
    
     text-align: center;
     padding: 5px;
     
    }
   
   h1{
     font-size: 10px;
    }
   
   .des{
     padding: 1px;
     text-align: center;
    
     padding-top: 2px;
           border-bottom-right-radius: 2px;
     border-bottom-left-radius: 2px;
   }
   button{
     margin-top: 20px;
     margin-bottom: 10px;
     background-color: #eee;
     border: none;
     border-radius: 5px;
     padding:10px;
   }
   button:hover{
     background-color: orangered;
     color: white;
     transition: .5s;
     cursor: pointer;
   }