.horizontal-form {
    display: flex;
    align-items: center;
  }
  
  .text-input {
    margin-right: 10px; 
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 60px;
    width: 250px;
    outline: none;
  }
  
  .dropdown {
    margin-right: 10px; 
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    height: 60px;
    width: 250px;
  }

  .B{
    height: 60px;
    width: 200px;
  }
  