.map-container {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .all{
    text-align: justify;
    display: inline-flex;
  }
  .head {
    color: #002244;
    margin-bottom: 15px;
  }
  .p{
    text-size-adjust: 10px;
    border-radius: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .location-container {
    
    align-items: center;
    justify-content: flex-start;
    text-align: justify;
    display: inline-flex;
  }
  
  .icon {
    color: #aaadaf;
    margin-right: 5px;
  }
  
  
  
  