.entire-section {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #13274F;
}

.pic{
  width: 100%;
  max-width: 300px;
  height: auto;
}

.logo-container {
  text-align: center;
  margin-top: 10px; 
}

.logo {
  max-width: 200px; 
  max-height: 200px;
  width: auto;
  height: auto;
}
  
  .logo-text {
    margin-top: 10px;
    color: white; 
  }
  
  .button-container {
    position: fixed;
    bottom: 20px;
    text-align: center;
    width: 100%;
  }
  
  .button {
    padding: 10px 20px;
    background-color: orangered; /* Green, adjust the color as needed */
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  