.linkStyles {
  text-decoration: none;
}

.rentalListingsMainText {
  color: #080054;
  font-size: 2.3rem;
  font-weight: 700;
  margin-left: 0.5rem;
}

.rentalListingsOtherColour {
  color: #17dafe;
}
