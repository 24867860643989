.rentalPropertyCard {
  margin: 20px;
  padding: 20px;
  border: 1px solid rgb(224, 215, 215);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}

.textPropertyInformation {
  margin-top: 10px;
  padding: 20px;
  border: 1px solid rgb(200, 83, 83);
  display: flex;
  flex-direction: column;
}

.linkStyles {
  text-decoration: none;
}


.listingsCardPriceText {
  color: #080054;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin: 10px 5px;
}

.listingsCardPropertyDetails {
  color: #080054;
  margin: 5px 1rem;
  font-size: 0.8rem;
}

.listingsCardPropertyDetailsFontBold {
  font-weight: 700;
}

.moreTextButton {
  text-align: center;
  margin: 0.3rem 1.5rem;
  border-radius: 10px;
  padding: 0.1rem 0.5rem;
  border: none;
  background-color: #ff6f00;
}
