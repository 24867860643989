.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  .spacer {
    flex-grow: 1;
  }
  
  .icon-container {
    margin-left: 10px;
    cursor: pointer;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    color:#ccc;
  }
  
  .dropdown-menu {
    position: absolute;
    font-size: 12px;
    top: 50px;
    left: 100px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
  }

  .left-content-right-image {
    display: flex;
    align-items: flex-start;
    margin-top: 80px;
  }
  
  .left-content {
    margin-right: 20px;
  }
  
  .greeting {
    bottom: 100px;
    color: #004170;
    font-size: big;
    margin-top: 100px;
  }
  
  .right-image img {
    height: 400px;
    width: 600px;
  }
  
  