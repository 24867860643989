

.pagination-button {
    position: fixed;
    top: 10px; 
    left: 10px; 
    background-color: hsl(206, 5%, 74%); 
    color: #fff; 
    font-size: 18px; 
    border: none;
    cursor: pointer;
    outline: none;
    padding: 10px;
    border-radius: 50%;
    width:40px;
  }
  
  .pagination-button:hover {
    background-color: #2980b9; 
  }
  