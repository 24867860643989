@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');



.toggle-button-group {
    display: flex;
    margin-top: 10px;
  }
  
  button {
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    border: none;
    background-color: #eee;
    border-radius: 25px;
  }
  
  button.selected {
    background-color: #004170;
    color: white; 
    
  }
  