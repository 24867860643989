.imageContainer {
  margin: 15px 3rem;
  width: 300px;
  height: 300px;
}

@media only screen and (max-width: 600px) {
  .imageContainer {
    margin: 15px 3rem;
    width: 200px;
    height: 200px;
  }
}