.navContainer {
  display: flex;
  justify-content: space-between;
  margin: 40px 5% 15px 5%;
}


.navLinks {
  font-size: 1.3rem;
  text-decoration: none;
  color: black;
  padding-right: 80px;
}

.navLinksLast {
  margin-right: 0;
  padding-right: 10px;
  display: inline-block;
  max-width: 5rem;
}

.navLinks:link {
  text-decoration: none;
}

.navLinks:visited {
  text-decoration: none;
  color: black;
}

.navLinks:focus {
  text-decoration: none;
}

.navLinks:hover {
  color: #17dafe;
  text-decoration: none;
}

.navLinks:active {
  text-decoration: none;
}

.showMobileLogo {
  display: none;
}

.desktopImageLogo {
  margin-top: 1.7rem;
}

@media only screen and (max-width: 600px) {
  /* For mobile: */
  .navContainer {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* margin: 40px 5% 15px 10%; */
  }

  .mobileLinksContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .showFullLogo {
    display: none;
  }

  .showMobileLogo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navLinks {
    margin: 5px auto;
    font-size: 1.2rem;
    text-decoration: none;
    padding-right: 0;
  }

  .navLinks:hover {
    color: #17dafe;
    text-decoration: none;
  }
  
  .linksContainerDesktop {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px 0;
    display: none;
  }

  .mobileMenuButton {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: none;
    border: none;
  }
}