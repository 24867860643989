.profile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .user-details{
    align-items: center;
    text-align: center;
  }
  .listing-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px; 
  }
  
  .add-listing-button {
    display: inline-block;
    margin-left: 10px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    
  }
  
 
  

  .image-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  
  .image-row {
    display: flex;
    justify-content: space-around;
    margin: 20px;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
   .uploadpic{
    height: 300px;
    width:300px;
    margin-right: 10px;
    border-radius: 25px;
   }

   .image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .edit-icon {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }
  .edit-icons {
    position: absolute;
    top: 5px;
    left: 5px;
  
  }  
  .image-row .eb {
    position: absolute;
  }
  .image-row .ec {
    position: absolute;
  }