.contactContainer {
  margin-inline: auto;
  max-width: 500px;
}

.contactContainer .contactHeading {
  font-family: "roboto";
  font-size: 1.2rem;
  text-align: center;
  text-transform: capitalize;
}