
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.header-container {
    display: flex;
    align-items: center;
  }
  .c-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    
   
  
  }

  
 
  .input{
    
    border-radius: 25px;
  }
  .spinner{
    margin-bottom: 90px;
    height: 100hv;
  }
  
  
  PaginationButton {
    margin-right: auto;
     
  }
  .house{
    color: #ddd;
  }
  .parr{
    font-size: larger;
    color: #004170;
  }
  
  /* p {
    padding-top: 20px;
    margin: 0 auto; 
    color: #002244;
  } */
  .par{
    font-size: large;
    color: #002244;
  }
  .sh{
    padding-top: 50px;
    margin-right: auto;
  }

 

.header-container2 {
    display: flex;
    justify-content: center; 
    align-items: center;
    
  }
  PaginationButton {
    justify-content: center; 
    align-items: center;
  }
  
  .add-listing-button {
    margin-left: 10px; 
    margin-bottom: 10px;
  }
  .button-container {
    position: fixed;
    bottom: 20px;
    text-align: center;
    width: 100%;
  }
  .image-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  
  .image-row {
    display: flex;
    justify-content: space-around;
    margin: 20px;
  }
  
  /* img {
    max-width: 100%;
    height: auto;
  } */
   .uploadpic{
    height: 300px;
    width:300px;
    margin-right: 10px;
    border-radius: 25px;
   }
  
  .button {
    padding: 10px 20px;
    background-color: #03C03C; 
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
 
  

.image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
  }
  
 
  
  

  
  
 
  