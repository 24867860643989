.innerParagraphAboutText {
  font-family: "Verdana, Geneva, Tahoma, sans-serif";
  width: 85%;
  text-align: center;
  padding: 2% 10%;
}

.headerAboutText {
  margin-left: auto;
  margin-right: auto;
  width: 30rem;
  font-size: 2rem;
  color: #707070;
  text-align: center;
}

@media only screen and (max-width: 600px) { 
  .headerAboutText { 
    max-width: 15rem;
  }
}