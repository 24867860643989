@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');


.nav{
    background-color: white;
    position: sticky;
    top: 0;
    padding: 0 85px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
   margin-left: 0%;
   margin-right: 0%;
}


nav .logo,
footer .logo{
    display: flex;
    align-items: center;
}

nav .logo a,
footer .logo a{
    margin-left: 0;
    font-weight: bold;
    font-size: 20px;
    padding: 0;
}

nav .logo img,
footer .logo img{
    width: 100%;
  max-width: 300px;
  height: auto;
}

.btn {
    width: 150px;
    background-color: #f0f0f0;
    border-color: #444;
    border: none;
    outline: none;
    height: 49px;
    border-radius: 49px;
    color: #444;
    font-weight: 600;
    text-transform: none;

  }

  strong {
    font-weight: bold;
  }

  .w{
    font-weight: bold;
    color:#13274F;
    margin-top: 20px;
  }

  h1{
    margin-top: 20px;
  }
  
  p{
    margin-top: 20px;
  }

  .hpic{
    display: flex;
  }

  .image-container {
    width: 100%;
    height: 600px; 
    overflow: hidden; 
  }
  
  .pic {
    width: 100%; 
    height: auto; 
    
    margin: 0 auto; 
  }

  .button-container {
    position: fixed;
    bottom: 20px;
    text-align: center;
    width: 100%;
    margin-top:10px;
  }

  .button{
    background-color: #0CAFFF;
  }
  